import React from "react";

const Home = () => {
  return (
    <div name="home" className="w-full h-full md:h-screen relative border-orange-400">
      <img
        className="md:h-screen w-full"
        src="https://images.pexels.com/photos/4364810/pexels-photo-4364810.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        style={{ objectFit: "cover" }}
        alt=""
      />
      <div className="headers absolute top-[5%] left-[08%] md:top-[30%] md:left-[15%] p-8">
        <h1 className="mainHeader text-3xl md:text-9xl font-bold tracking-widest md:font-normal">
          SR VIDEOS
        </h1>
        <h3 className="uppercase hidden md:block text-center tracking-widest text-lg md:text-5xl md:text-gray-600 font-bold md:font-normal">
          Filmy ślubne
        </h3>
      </div>
    </div>
  );
};

export default Home;
