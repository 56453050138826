import React from "react";
import { ImVideoCamera } from "react-icons/im";
import { AiFillYoutube } from "react-icons/ai";
import { RiMovie2Line } from "react-icons/ri";
import { GiDeliveryDrone } from "react-icons/gi";
import { TbMovie } from "react-icons/tb";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-full max-w-[1140px] mx-auto flex flex-col justify-center items-center lg:py-20"
    >
      <h1 className="w-full max-w-[1140px] text-center md:text-right text-3xl md:text-6xl font-bold text-[#206EA6] tracking-widest md:font-normal md:mt-24 lg:mt-16">
        O mnie
      </h1>
      <p className="max-w-[1140px] mx-auto text-justify text-lg px-2 py-10 leading-7 text-gray-800">
        Hej! Tu Michał. Jestem pasjonatem video z okolic Gniezna. Zajmuję się filmowaniem
        uroczystości, eventów, produkcją teledysków muzycznych, spotów reklamowych, oraz nagraniami
        z drona. Ze mną zrealizujesz swoją wymarzoną produkcję od A do Z - zadbam o rozpisanie
        scenariusza, realizację ujęć, na montażu i postprodukcji kończąc, oddając w Twoje ręce
        gotowy materiał.
      </p>
      <div className="relative max-w-[1140px] mx-auto isolate overflow-hidden bg-gray-900 lg:px-0 sm:py-32 md:py-0 shadow-xl md:rounded-md">
        <img
          src="https://images.pexels.com/photos/5563239/pexels-photo-5563239.jpeg"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center bg-black/90"
        />
        <div className="mx-auto px-6 lg:px-8 lg:py-8 mt-4">
          <div className="mx-auto pl-0 lg:pl-8 text-center lg:text-left">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Czym się zajmuję:
            </h2>
            <p className="mt-6 text-xl font-bold leading-8 text-gray-100">
              Produkcja wideoklipów, spotów, filmów promocyjnych.
            </p>
            <p className="text-xl font-bold leading-8 text-gray-100">
              Realizacja teledysków ślubnych.
            </p>
          </div>
          <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            {/* <div className="grid grid-cols-1 gap-y-6 gap-x-8 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
            <a href="/">Link 1</a>
            <a href="/">Link 2</a>
            <a href="/">Link 3</a>
            <a href="/">Link 4</a>
          </div> */}
            <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-5 lg:mt-0">
              <div className="flex flex-col justify-center items-center bg-black/20 p-2 rounded-md">
                <dt className="text-base leading-7 text-[#D946EF]">
                  <ImVideoCamera size={60} />
                </dt>
                <dd className="text-xl font-bold leading-9 tracking-wide text-white">
                  Filmy ślubne
                </dd>
              </div>
              <div className="flex flex-col justify-center items-center bg-black/20 p-2 rounded-md">
                <dt className="text-base leading-7 text-red-600">
                  <AiFillYoutube size={60} />
                </dt>
                <dd className="text-xl font-bold leading-9 tracking-tight text-white">Teledyski</dd>
              </div>
              <div className="flex flex-col justify-center items-center bg-black/20 p-2 rounded-md">
                <dt className="text-base leading-7 text-orange-500">
                  <TbMovie size={60} />
                </dt>
                <dd className="text-xl font-bold leading-9 tracking-wide text-white">Spoty</dd>
              </div>
              <div className="flex flex-col justify-center items-center bg-black/20 p-2 rounded-md">
                <dt className="text-base leading-7 text-yellow-400">
                  <RiMovie2Line size={60} />
                </dt>
                <dd className="text-xl font-bold leading-9 tracking-wide text-white">Montaż</dd>
              </div>
              <div className="flex flex-col justify-center items-center bg-black/20 p-2 rounded-md">
                <dt className="text-base leading-7 text-green-400">
                  <GiDeliveryDrone size={60} />
                </dt>
                <dd className="text-xl font-bold leading-9 tracking-wide text-white md:text-center">
                  Nagrania z drona
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="mt-8 w-full bg-black/70 text-justify px-8 py-8 leading-7 text-gray-100">Wychodząc naprzeciw oczekiwaniom klientów, staram się spojrzeć indywidualnie na potrzeby każdego z nich. W związku z tym skontaktuj się ze mną - napisz jaki film chcesz zrealizować, a ja odpowiem wysyłając wycenę.</div>
      </div>
      
    </div>
  );
};

export default About;
